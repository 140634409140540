<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="d-flex align-items-center mb-2 mb-lg-0">
                <div class="me-2">
                  <i class="uil uil uil-setting display-6 text-primary"></i>
                </div>
                <div class="">
                  <h4 class="fw-medium font-size-20 text-dark mb-0">
                    {{ title }}
                  </h4>
                  <small class="d-block font-size-13 text-muted fw-normal">Account Info and Setting</small>
                </div>
              </div>
              <div class="ms-auto" v-if="account_type!=='reseller'">
                  <a :href="`skype:${skype_id}?chat`" class="btn btn-info me-2 mb-2 mb-lg-0">
                  <i class="uil uil-skype-alt"></i> Support on Skype</a>
                  <a :href="`https://t.me/${telegram_id}`" target="_blank" class="btn btn-info mb-2 mb-lg-0">
                  <i class="uil uil-telegram-alt"></i> Support on Telegram</a>
              </div>
            </div>
            
          </div>
          <div class="card-body">
            <div class="row">
              
              <div class="col-lg-12">
                <div class="mt-1">
                  
                  <div class="row" :class="{' mb-3':account_type!=='admin' && isSubAccount==false }">
                    <div class="col-md-3 col-12">
                      <h5 class="font-size-14 mb-1 text-dark">Login Username</h5>
                      <p class="mb-3 text-primary">{{username || '-'}}</p>
                    </div>
                    <div class="col-md-3 col-12" v-if="account_type=='merchant'">
                      <h5 class="font-size-14 mb-1 text-dark">Merchant Code</h5>
                      <p class="mb-3 text-primary">{{merchant_code || '-'}}</p>
                    </div>
                    <div class="col-md-3 col-12" v-if="account_type=='merchant'">
                      <h5 class="font-size-14 mb-1 text-dark">Merchant Key</h5>
                      <p class="mb-3 text-primary">{{merchant_key || '-'}}</p>
                    </div>
                    <div class="col-md-3 col-12" v-if="account_type!=='admin'">
                      <h5 class="font-size-14 mb-1 text-dark">Code for Support</h5>
                      <p class="mb-3 text-primary">{{code_for_support_skype || '-'}}</p>
                    </div>
                  </div>
                  <div class="row" v-if="(account_type=='reseller' || account_type=='merchant' ) && isSubAccount==false">
                    <div class="col-12">
                      <h5 class="fw-medium text-dark font-size-14 mb-2">Current Rate &amp; Extra Charges</h5>
                      <div class="table-responsive text-nowrap font-size-14 mb-3">
                        <table class="table table-bordered align-middle placeholder-glow" v-if="pageLoading">
                        <thead class="custom_top_data_bar text-uppercase">
                          <tr>
                            <th><span class="placeholder col-12 col-lg-8"></span></th>
                            <th><span class="placeholder col-12 col-lg-8"></span></th>
                            <th><span class="placeholder col-12 col-lg-8"></span></th>
                            <th><span class="placeholder col-12 col-lg-8"></span></th>
                            <th><span class="placeholder col-12 col-lg-8"></span></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><span class="placeholder col-12 col-lg-8"></span></td>
                            <td>
                              <span class="placeholder col-12 col-lg-8"></span>
                            </td>
                            <td>
                              <span class="placeholder col-12 col-lg-8"></span>
                            </td>
                            <td>
                              <span class="placeholder col-12 col-lg-8"></span>
                            </td>
                            <td>
                              <span class="placeholder col-12 col-lg-8"></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                          <table class="table table-bordered align-middle mb-0 table-striped" v-else>
                            <thead class="custom_top_data_bar text-uppercase font-size-12">
                              <tr>
                                <th>Contract <br>Currency</th>
                                <th>Payin Rate <br>and Extra Charges</th>
                                <th>Payout Rate <br>and Extra Charges</th>
                                <th>Settlement Rate<br>and Extra Charges</th>
                                <th>E-Wallet Rate <br> and Extra Charges</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(value, index) in current_info" :key="index">
                                <td>{{value.contract_currency}}</td>
                                <td>
                                  <div class="row">
                                    <div class="col">
                                    {{parseFloat(value.payin_rate).toFixed(2)}}%
                                    </div>
                                    <div class="col">
                                    + {{parseFloat(value.payin_extra_charges).toFixed(2)}}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div class="row">
                                    <div class="col">
                                    {{parseFloat(value.payout_rate).toFixed(2)}}%
                                    </div>
                                    <div class="col">
                                    + {{parseFloat(value.payout_extra_charges).toFixed(2)}}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div class="row">
                                    <div class="col">
                                    {{parseFloat(value.settlement_rate).toFixed(2)}}%
                                    </div>
                                    <div class="col">
                                    + {{parseFloat(value.settlement_extra_charges).toFixed(2)}}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div class="row ">
                                    <div class="col">
                                    {{parseFloat(value.ewallet_rate).toFixed(2)}}%
                                    </div>
                                    <div class="col">
                                    + {{parseFloat(value.ewallet_extra_charges).toFixed(2)}}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                      </div>
                    </div>
                    <div class="col-12 mb-2">
                      <hr>
                    </div>
                  </div>
                  <form>
                    <div class="mb-3 position-relative d-none">
                      <label>Name</label>
                      <input
                        v-model="formData.name"
                        type="text"
                        class="form-control"
                        placeholder="Name"
                        name="name"
                        :class="{
                          'is-invalid':
                            submitted && $v.formData.name.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.formData.name.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.formData.name.required"
                          >Name is required.</span
                        >
                      </div>
                    </div>
                    <div class="mb-3 position-relative">
                      <label>Current Password</label>
                      <input
                        v-model="formData.current_password"
                        :type="passwordFieldType_current"
                        class="form-control"
                        placeholder="Current Password"
                        name="name"
                        :class="{
                          'is-invalid':
                            submitted && $v.formData.current_password.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.formData.current_password.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.formData.current_password.required"
                          >Current password is required.</span
                        >
                      </div>
                      <div
                        id="eye_current"
                        class="user-select-none"
                        @click="switchVisibility_current()"
                      >
                        <i
                          :class="classicon_current"
                          id="classicon_current"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3 position-relative">
                          <label>New Password</label>
                          <div>
                            <input
                              v-model="formData.password"
                              :type="passwordFieldType"
                              name="password"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  submitted && $v.formData.password.$error,
                              }"
                              placeholder="New Password"
                            />
                            <div
                              v-if="submitted && $v.formData.password.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.formData.password.required"
                                >New password is required.</span
                              >
                              <span v-if="!$v.formData.password.minLength"
                                >Password must be at least 8 characters.</span
                              >

                              <span v-if="!$v.formData.password.alpha">
                                Password must contain at least one number and
                                both lower and uppercase letters and special
                                characters. (e.g.: MercHant357#@, Admin12#@,
                                etc.)</span
                              >
                            </div>
                            <div
                              id="eye"
                              class="user-select-none"
                              @click="switchVisibility()"
                            >
                              <i
                                :class="classicon"
                                id="eyetype"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3 position-relative">
                          <label>Confirm Password</label>
                          <input
                            v-model="formData.confirmPassword"
                            :type="passwordFieldType2"
                            name="confirmPassword"
                            class="form-control"
                            :class="{
                              'is-invalid':
                                submitted && $v.formData.confirmPassword.$error,
                            }"
                            placeholder="Confirm Password"
                          />
                          <div
                            v-if="
                              submitted && $v.formData.confirmPassword.$error
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.formData.confirmPassword.required"
                              >Confirm password is required.</span
                            >
                            <span
                              v-else-if="
                                !$v.formData.confirmPassword.sameAsPassword
                              "
                              >Confirm password does not match the new
                              password.</span
                            >
                          </div>
                          <div
                            id="eye2"
                            class="user-select-none"
                            @click="switchVisibility2()"
                          >
                            <i
                              :class="classicon2"
                              id="eyetype2"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <p class="text-muted font-size-13">
                        <i
                          class="
                            uil uil-exclamation-triangle
                            font-size-16
                            text-warning
                            me-2
                          "
                        ></i>
                        Password is Case Sensitive.
                      </p>
                    </div>
                    <div class="mb-3 d-flex">
                      <button type="button" @click="resetData" class="btn btn-light me-2" :disabled="loading || buttonLoading[1] ">
                      <i class="uil-redo me-1"></i> 
                      <span v-if="buttonLoading[1]"> Resetting..</span>
                      <span v-else>  Reset </span>
                      <span class="spinner-border spinner-border-sm ms-2" v-if="buttonLoading[1]" role="status" aria-hidden="true"></span>
                      </button>

                      <button type="button" @click="save" class="btn btn-info me-2" :disabled="loading || buttonLoading[0]">
                      <i class="uil uil-check me-1"></i>
                      <span v-if="buttonLoading[0]"> Submitting...</span>
                      <span v-else>  Submit </span>
                      <span class="spinner-border spinner-border-sm ms-2" v-if="buttonLoading[0]" role="status" aria-hidden="true"></span>
                      </button>
                      
                     
                    </div>
                    <!-- <div class="mt-4" v-if="account_type=='admin' && (accessUsername=='bryan' || accessUsername=='kent1')">
                      <hr>
                       <button type="button" @click="deleteAllRecords" class="btn btn-dark me-2" :disabled="loading || deleteLoading">
                        <i class="uil uil-trash-alt me-1"></i>
                        <span v-if="deleteLoading"> Resetting...</span>
                        <span v-else>  Reset All Records </span>
                        <span class="spinner-border spinner-border-sm ms-2" v-if="deleteLoading" role="status" aria-hidden="true"></span>
                        </button>
                    </div> -->
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card d-none">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="d-flex align-items-center mb-2 mb-lg-0">
                <div class="me-2">
                  <!-- <i class="uil uil uil-setting display-6 text-primary"></i> -->
                  <img :src="require('@/assets/images/google-authenicator.png')" alt="" height="44">
                </div>
                <div class="">
                  <h4 class="fw-medium font-size-20 text-dark mb-0">
                    Two-Factor Authentication
                  </h4>
                  <small class="d-block font-size-13 text-muted fw-normal">Increase your account's security by enabling two-factor authentication (2FA).</small>
                </div>
              </div>
            </div>
            
          </div>
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-12 text-muted">
                <div class="mb-3">Enhance the security of your account by enabling two-factor authentication (2FA). Receive verification codes through google authenticator app, ensuring heightened protection for your account. 
                <div class="d-lg-block">This method remains effective even when your phone is offline. </div>
                </div>
                <div class="mb-3">First, download Google Authenticator from the <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en" target="_blank">Google Play Store</a> or the <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">iOS App Store</a>.</div>
               
                <div v-if="loading">
                  <div class="text-muted">Loading Setting...</div>
                </div>
                <div v-else>
                  <button class="btn btn-info" @click="enable2FA" v-if="!otpStatus.is_enabled">Set Up Authenticator App </button>
                  <div class="col-12 col-lg-6 col-md-8 mb-3" v-else>
                    <div class="bg-white  rounded-3 border border-2 p-4">
                      <div class="d-md-flex align-items-center">
                        <div class="me-3"><div class="mb-2 mb-md-0"><svg enable-background="new 0 0 24 24" height="36" viewBox="0 0 24 24" width="36" focusable="false" class=" NMm5M" aria-hidden="true"><rect fill="none" height="24" width="24"></rect><path d="M9.5,6.5v3h-3v-3H9.5 M11,5H5v6h6V5L11,5z M9.5,14.5v3h-3v-3H9.5 M11,13H5v6h6V13L11,13z M17.5,6.5v3h-3v-3H17.5 M19,5h-6v6 h6V5L19,5z M13,13h1.5v1.5H13V13z M14.5,14.5H16V16h-1.5V14.5z M16,13h1.5v1.5H16V13z M13,16h1.5v1.5H13V16z M14.5,17.5H16V19h-1.5 V17.5z M16,16h1.5v1.5H16V16z M17.5,14.5H19V16h-1.5V14.5z M17.5,17.5H19V19h-1.5V17.5z M22,7h-2V4h-3V2h5V7z M22,22v-5h-2v3h-3v2 H22z M2,22h5v-2H4v-3H2V22z M2,2v5h2V4h3V2H2z"></path></svg></div></div>
                        <div>
                          
                          <div class="mb-0 font-size-18 fw-medium text-dark">Authenticator</div>
                          <div class="font-size-13 text-muted mb-1 text-capitalize mb-2 mb-md-2" v-b-tooltip.top.hover :title="otpStatus.date_created">Added {{ convertDate2DayWeekandDate(otpStatus.date_created) || '-' }}</div>
                          <button class="text-nowrap btn btn-sm border border-2 border-info btn-outline-info" @click="change2AF">Change Authenticator App</button>
                        </div>
                        <div class="ms-auto mt-2 mt-md-0"> <a class="text-info font-size-20 d-flex align-items-center" href="javascript:void(0)" @click="remove2FA" ><i class="mdi mdi-trash-can-outline"></i> <span class="d-md-none font-size-14 ms-1">Remove</span></a></div>
                      </div>
                    </div>
                    
                  </div>
                </div>

                <pre>{{ otpStatus }}</pre>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="''" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <googleAuthenticator ref="modalFunc" :data="{'accessUsername':accessUsername, 'assessToken':accessToken, 'updateData':updateData}"  @callParentFunction="getOTPStatus()" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import googleAuthenticator from "@/views/pages/app/modal/googleAuthenticator";
import { required, minLength, sameAs, helpers } from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
/**
 * Settings page
 */
 const PageTitle = "Profile Setting"

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common,
    googleAuthenticator
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      updateData:{},
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      passwordFieldType_current: "password",
      classicon_current: "uil uil-eye-slash",
      passwordFieldType: "password",
      classicon: "uil uil-eye-slash",
      passwordFieldType2: "password",
      classicon2: "uil uil-eye-slash",
      username: "",
      merchant_code: "",
      merchant_key: "",
      account_type:"",
      code_for_support_skype:"",
      otpStatus:{
        is_enabled:false,
        date_created: "",
      },
      formData: {
        name:"",
        current_password: "",
        password: "",
        confirmPassword: "",
        otp: "",
      },
      submitted:false,
      loading:false,
      pageLoading:false,
      buttonLoading:[false,false,false],
      deleteLoading:false,
      create_modify_permission:false,
      skype_id:"",
      telegram_id:"",
      current_reseller_acccount_db_code:"",
      current_merchant_acccount_db_code:"",
      current_info:[],
      isSubAccount: true
    };
  },
  validations: {
    formData: {
      name: {
        required,
      },
      current_password: {
        required,
      },
      password: {
        required,
        alpha,
        minLength: minLength(8),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  middleware: "authentication",
  async mounted(){
   
    this.title = PageTitle
    this.items[1].text = PageTitle
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    await this.reload()
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    //this.getOTPStatus();

    if (this.account_type=='reseller' && this.isSubAccount==false){
      this.getCurrentResellerInfo();
    }else if (this.account_type=='merchant' &&  this.isSubAccount==false){
      this.getCurrentMerchantInfo();
    }
    
  }, 
  created(){
   
  },
  methods:{
     resetData() {
      this.submitted = false;
      this.formData.current_password = "";
      this.formData.password = "";
      this.formData.confirmPassword = "";
    },
    switchVisibility_current() {
      (this.classicon_current =
        this.classicon_current === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType_current =
          this.passwordFieldType_current === "password" ? "text" : "password");
    },
    switchVisibility() {
      (this.classicon =
        this.classicon === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password");
    },
    switchVisibility2() {
    (this.classicon2 =
      this.classicon2 === "uil uil-eye-slash"
        ? "uil uil-eye"
        : "uil uil-eye-slash"),
      (this.passwordFieldType2 =
        this.passwordFieldType2 === "password" ? "text" : "password");
    },
    convertDate2DayWeekandDate(value) {
        // Convert value to Date object
        const date = new Date(value);
        // Current time
        const now = new Date();
        // Time difference in milliseconds
        const diff = now - date;
        // Convert milliseconds to minutes
        const diffMinutes = Math.floor(diff / (1000 * 60));
        // Convert minutes to hours
        const diffHours = Math.floor(diff / (1000 * 60 * 60));
        // Convert hours to days
        const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
        // Convert days to weeks
        const diffWeeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));

        if (diffMinutes < 1) {
            return 'just now';
        } else if (diffMinutes < 60) {
            return `${diffMinutes} mins ago`;
        } else if (diffHours < 24) {
            return `${diffHours} hr ago`;
        } else if (diffHours < 48) {
            return '1 day ago';
        } else if (diffDays < 7) {
            return `${diffDays} days ago`;
        } else if (diffWeeks < 2) {
            return '1 week ago';
        } else {
            // Return actual date for anything older than a week
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString(undefined, options);
        }
    },
    enable2FA(){
      setTimeout(() =>  this.$refs.modalFunc.showModal(),100)
    },
    change2AF(){
      setTimeout(() =>  this.$refs.modalFunc.showChangeModal(),100)
    },
    remove2FA(){
      Swal.fire({
          icon: 'info',
          title: `Confirmation Required`,
          html: `Are you sure you want to disable 2FA and unlink your authenticator app from your account?`,
          confirmButtonColor: '#FA6541',
          iconColor: '#8d2a9f',
          showCancelButton: true,
          confirmButtonText: "Yes"
        }).then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.loading = true
            this.disabled = true
            var bodyFormData = new FormData();
            bodyFormData.append("accessToken", this.accessToken);
            bodyFormData.append("accessUsername", this.accessUsername);
            axios({
              method: "post",
              url:  "https://www.seveninblank.com/projects/api/anythings.php?page=remove2AF",
              data: bodyFormData,
              headers: {
                "Content-Type": "multipart/form-data"
              },
            }).then((response) => {
              this.returnData = []
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: `Removal Successful`,
                  html: `Your account's 2FA has been disabled and unlinked from your authenticator app!`,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                })
                const updateData = {
                    "otp_enabled": false
                }
                this.$refs.commonFunc.updateData(JSON.stringify(updateData));
                this.otpStatus.is_enabled=false;
                this.getOTPStatus();
              } else if (resData.status == 440) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                  path: "/login",
                });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
              }
              this.loading = false
              this.disabled = false
              this.$Progress.finish();
            }).catch((error) => {
              this.disabled = false;
              this.loading = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            });
          }
        })
    },
    getOTPStatus(){
      this.$Progress.start();
      this.loading = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessUsername",  this.accessUsername);
      bodyFormData.append("accessToken", this.accessToken);
      axios({
          method: "post",
          url:  "https://www.seveninblank.com/projects/api/anythings.php?page=getOTPStatus",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData)
            const demoEnabledData ={
              is_enabled: true,
              date_created: "2024-04-01 11:26:06"
            }
            console.log(demoEnabledData)
            const demoDisabledData ={
              is_enabled: false,
              date_created:""
            }
            console.log(demoDisabledData)

            this.otpStatus={
              is_enabled: demoEnabledData.is_enabled,
              date_created: demoEnabledData.date_created,
            }
            //this.reload()
          } 
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.loading = false;
          this.$Progress.finish();
      })
      .catch((error)=> {
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
      });
    },
    getMerchantData(){
      this.$Progress.start();
      this.loading = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessUsername",  this.accessUsername);
      bodyFormData.append("accessToken", this.accessToken);
      axios({
          method: "post",
          url:  appConfig.APIHost + 'controller/account/getDashboardData',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData)
            //this.reload()
          } 
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.loading = false;
          this.$Progress.finish();
      })
      .catch((error)=> {
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
      });
    },
    getCurrentResellerInfo(){
      this.$Progress.start();
      this.pageLoading = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessUsername",  this.accessUsername);
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "9999");
      bodyFormData.append("reseller", this.current_reseller_acccount_db_code);
      axios({
          method: "post",
          url: appConfig.APIHostAdmin + 'controller/admin/getContractListForReseller',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData)
            this.current_info = resData.data;
            //this.reload()
          } 
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.pageLoading = false;
          this.$Progress.finish();
      })
      .catch((error)=> {
          this.pageLoading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
      });
    },
    getCurrentMerchantInfo(){
      this.$Progress.start();
      this.pageLoading = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessUsername",  this.accessUsername);
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "9999");
      bodyFormData.append("reseller", "all");
      bodyFormData.append("merchant", this.current_merchant_acccount_db_code);
      axios({
          method: "post",
          url:   appConfig.APIHostAdmin + 'controller/admin/getContractListForMerchant',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData)
            this.current_info = resData.data;
          } 
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.pageLoading = false;
          this.$Progress.finish();
      })
      .catch((error)=> {
          this.pageLoading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
      });
    },
    getData(){
      this.$Progress.start();
      this.loading = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessUsername",  this.accessUsername);
      bodyFormData.append("accessToken", this.accessToken);
      axios({
          method: "post",
          url: appConfig.DemoAPI,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData)
            //this.reload()
          } 
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.loading = false;
          this.$Progress.finish();
      })
      .catch((error)=> {
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
      });
    },
    save() {
        this.submitted = true
        this.$v.formData.$touch();
        if (this.$v.formData.$invalid) {
          return;
        } else {
            this.$Progress.start();
            this.loading = true
            this.buttonLoading[0] = true
            var bodyFormData = new FormData();
            bodyFormData.append("accessToken", this.accessToken);
            bodyFormData.append("accessUsername",  this.accessUsername);
            bodyFormData.append("name",  this.formData.name);
            bodyFormData.append("currentPassword",  this.formData.current_password);
            bodyFormData.append("newPassword",  this.formData.password);
            var postUrl = appConfig.APIHostAdmin + 'controller/admin/updateAccDetails'
            // if (this.accessUsername=='admin'){
            //   postUrl = appConfig.DemoAPI
            // }else{
            //   if (this.account_type == "admin"){
            //     postUrl = appConfig.APIHostAdmin + 'controller/admin/updateAdminDetails'
            //   }else{
            //     postUrl = appConfig.DemoAPI
            //   }
            // }

            const sendRequest = async () => {
              try {
                const response = await 
                axios.post(
                    postUrl, 
                    bodyFormData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
                if(response){
                  var resData = response.data;
                  if (resData.status == 200) {
                    console.log(resData)
                    const updateData = {
                        "name": this.formData.name,
                    }
                    this.$refs.commonFunc.updateData(JSON.stringify(updateData))
                    this.formData.name = "";
                    this.resetData()
                    this.reload()

                    Swal.fire({
                      icon: 'success',
                      title: 'Password Updated',
                      html: `Password have been successfully updated!`,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done')
                    })
                    .then(() => {
                      this.$router.push({
                        path: "/logout",
                      });
                    })
                  }
                  else if (resData.status == 440) {
                    console.log(resData)
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        html: `${resData.message}.`,
                        confirmButtonColor: '#222',
                        confirmButtonText: this.$t('siteLang.Done'),
                      })
                      localStorage.clear();
                      this.$router.push({
                          path: "/login",
                    });
                  }
                  else{
                    console.log(resData)
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      html: resData.message,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done')
                    })
                  }
                  
                  this.loading = false;
                  this.submitted = false
                  this.buttonLoading[0] = false
                  this.$Progress.finish();
                }
                } catch (error) {
                  this.buttonLoading[0] = false
                  this.loading = false;
                  this.$Progress.finish();
                  //Swal.fire("Error", error, "error");
                  Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                }
            };

            sendRequest();
        }
    },
    
    // deleteAllRecords() {
    //   this.$Progress.start();
    //   this.deleteLoading = true;

    //   var bodyFormData = new FormData();
    //   bodyFormData.append("accessToken", this.accessToken);
    //   bodyFormData.append("accessUsername", this.accessUsername);
    //   axios({
    //     method: "post",
    //     url: appConfig.APIHostAdmin + 'controller/sql/resetDatabase',
    //     data: bodyFormData,
    //     headers: {
    //       "Content-Type": "multipart/form-data"
    //     },
    //   }).then((response) => {
    //     this.returnData = []
    //     var resData = response.data;
    //     if (resData.status == 200) {
    //       console.log(resData)
    //       Swal.fire({
    //         icon: 'success',
    //         title: 'Records Reset Success',
    //         html: 'All records have been successfully reset!',
    //         confirmButtonColor: '#FA6541',
    //         confirmButtonText: this.$t('siteLang.Done')
    //       }).then(() => {
    //         this.$router.push({
    //             name: "dashboard",
    //         });
    //       })
    //     } else if (resData.status == 401) {
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'Oops...',
    //         html: `${resData.message}.`,
    //         confirmButtonColor: '#222',
    //         confirmButtonText: this.$t('siteLang.Done'),
    //       })
    //       this.$refs.commonFunc.clearData()
    //       this.$router.push({
    //         path: "/login",
    //       });
    //     } else {
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'Oops...',
    //         html: `${resData.message}.`,
    //         confirmButtonColor: '#222',
    //         confirmButtonText: this.$t('siteLang.Done'),
    //       })
    //     }
    //     this.deleteLoading = false;
    //     this.$Progress.finish();
    //   }).catch((error) => {
    //     this.deleteLoading = false;
    //     this.$Progress.finish();
    //     console.log(error);
    //   });
    // },
    reset() {
        this.submitted = true
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        } else {
            this.$Progress.start();
            this.loading = true
            this.buttonLoading[1] = true
            var bodyFormData = new FormData();
            bodyFormData.append("accessToken", this.accessToken);
            bodyFormData.append("phone",  this.accessPhone);
            axios({
                method: "post",
                url: appConfig.DemoAPI,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((response)=>{
                var resData = response.data;
                if (resData.status == 200) {
                  console.log(resData)
                  Swal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    html: `API ID and API Hash have been successfully deleted and reset!`,
                    confirmButtonColor: '#FA6541',
                    confirmButtonText: this.$t('siteLang.Done')
                  })
                  const updateData = {
                    "apiID": 'YOUR_API_ID',
                    "apiHash": 'YOUR_API_HASH',
                 }
                 this.$refs.commonFunc.updateData(JSON.stringify(updateData))
                 this.reload()
                   
                } 
                else if (resData.status == 440){
                  Swal.fire(
                      {
                      icon: 'error',
                      title: 'Oops...',
                      html: `${resData.message}.`,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done'),
                    })
                    localStorage.clear();
                    this.$router.push({
                        path: "/login",
                  });
                }
                else {
                    Swal.fire(
                      {
                      icon: 'error',
                      title: 'Oops...',
                      html: `${resData.message}.`,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done'),
                    })
                }
                this.loading = false;
                this.submitted = false
                this.buttonLoading[1] = false
                this.$Progress.finish();
            })
            .catch((error)=> {
                this.buttonLoading[1] = false
                this.loading = false;
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.current_reseller_acccount_db_code = data.reseller_info.account_db_code
          this.current_merchant_acccount_db_code = data.merchant_info.account_db_code
          this.account_type = data.account_type;
          this.username = data.username;
          this.merchant_key = data.merchant_info.merchant_key;
          this.merchant_code = data.merchant_info.merchant_code;
          this.skype_id = data.skype_id;
          this.telegram_id = data.telegram_id;
          if (this.account_type=='reseller'){
            this.code_for_support_skype = data.reseller_info.reseller_code
            this.isSubAccount = data.reseller_info.sub_account
          }
          else if (this.account_type=='merchant'){
            this.code_for_support_skype = data.merchant_info.merchant_code;
            this.isSubAccount = data.merchant_info.sub_account
          }
          else{
            this.code_for_support_skype = ''
          }
          this.formData.name = data.name;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  }
};
</script>
<style scoped>
#eye_current,
#eye,
#eye2,
#eye3{
  position: absolute;
  top: 32px;
  padding: 5px 10px;
  right: 5px;
  text-align: center;
  width: 30px;
  color: #999;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
}
</style>