<template>
  <div>
     <b-modal @hidden="resetModal" ref="modal-2FA-setup" size="md" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <div class="d-flex align-items-center">
                <div class="me-2">
                  <!-- <i class="uil uil uil-setting display-6 text-primary"></i> -->
                  <img :src="require('@/assets/images/google-authenicator.png')" alt="" height="28">
                </div>
                <div v-if="requestChangeNew">
                  Change Authenticator App
                </div>
                <div v-else>
                  Set Up Authenticator App
                </div>
              </div>
           </h5>
        </template>
        <div class="row align-items-top">
          <div class="mb-3 col-12 col-md-12">
              <pre>{{ formData }}</pre>
              <div class="bg-light p-3 rounded mb-3" v-if="showQR">
                <div class="mb-3" v-if="requestChangeNew">You won't be able to use your old authenticator app for codes or 2-Step Verification for your Account</div>
                <div class="mb-1">1. In the Google Authenticator app tap the <b class="fw-semibold text-dark">+</b></div>
                <div class="mb-3">2. Choose <b class="fw-semibold text-dark">Scan a QR code</b></div>
                <div class="text-center">
                      <div v-if="loading">
                        <div
                          class="spinner-border text-secondary my-2"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                        <br />
                        <div class="text-muted">Loading...</div>
                      </div>
                      <div class="rounded mb-1" v-else>
                        <img :src="otpQrCode" alt="" class="rounded">

                        <div class="mt-3"><a href="javascript:void(0)" @click="showQR=false" class="text-primary text-center fw-medium">Can&prime;t scan it?</a></div>
                      </div>
                </div>
              </div>
              <div class="bg-light p-3 rounded mb-3" v-else>
                <ol class="my-2 px-4 mx-0 text-body">
                  <li class="mb-1">In the Google Authenticator app tap the <b class="fw-medium text-dark">+</b> then tap <b class="fw-medium text-dark">Enter a setup key</b></li>
                  <li class="mb-1">Enter your account name <b class="fw-medium text-dark">{{defaultName}} ({{ accessUsername }})</b>  and this key:<br><b class="fw-medium text-dark">{{ formData.key }}</b> <i class="uil uil-copy text-primary cursor-pointer font-size-18" @click="copyText(formData.key)" id="copyButton1"></i>
                      <div class="position-relative">
                        <b-tooltip target="copyButton1">{{copyTitle}}</b-tooltip>
                      </div>
                  </li>
                  <li class="mb-1">Make sure  <b class="fw-medium text-dark">Time based</b> is selected</li>
                  <li class="mb-1">Tap <b class="fw-medium text-dark">Add</b> to finish</li>
                </ol>
                <div class="text-center">
                  <div class="mt-3"><a href="javascript:void(0)" @click="showQR=true" class="text-primary text-center fw-medium">Back to Scan QR</a></div>
                </div>
              </div>
              <label>Password<p class="text-muted font-size-13 d-block fw-normal mb-1">Your current password is required to register a two-factor authenticator app.</p>
              </label>
              <div class="position-relative">
                <input
                    v-model="formData.password"
                    :type="passwordFieldType"
                    name="password"
                    class="form-control"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.password.$error,
                    }"
                    placeholder="Password"
                    />
                <div
                    v-if="modalSubmit && $v.formData.password.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData.password.required"
                      >Password is required.</span
                      >
                </div>
                <div
                    id="eye"
                    class="user-select-none"
                    @click="switchVisibility()"
                    >
                    <i
                      :class="classicon"
                      id="eyetype"
                      aria-hidden="true"
                      ></i>
                </div>
              </div>
          </div>
          <div class="mb-3 col-12 col-md-12">
              <label>Enter the 6-digit code you see in the app</label>
              <div class="position-relative">
              <input type="tel" class="form-control" placeholder="Enter Code" autocomplete="" v-model.trim="formData.otp"
                maxlength="6"
                @input="inputNumberOnly($event, 'formData','otp')"
                :class="{
                  'is-invalid': modalSubmit && $v.formData.otp.$error
                }">
                <div
                  v-if="modalSubmit && $v.formData.otp.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.formData.otp.required">
                    Code is required.</span
                  >
                  <span v-if="!$v.formData.otp.alpha">
                    Invalid Code format.
                  </span>

                  <span v-if="!$v.formData.otp.minLength">
                    Code must be at least 6-digit
                    code.</span
                  >
                </div>
              </div>
          </div>
            
          <div class="col-12">
            <hr>
            <div class="d-grid gap-2 d-md-block text-end">
                <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-2FA-setup'].hide()">Cancel</button>
                <button type="button" class="btn fw-medium btn-info" @click="addRequest" :disabled="modalLoading || loading">
                <span v-if="modalLoading"> Verifying Code...</span>
                <span v-else> Verify </span>
                <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                </button>
            </div>
          </div>
        </div>
     </b-modal>
     <Common ref="commonFunc" />
  </div>
</template>

<script>
  import appConfig from "@/app.config";
  import axios from "axios";
  import Swal from "sweetalert2";
  import Common from "@/components/common";
  import { 
    required, 
    minLength, 
    //sameAs, 
    helpers, 
    //url
  } from "vuelidate/lib/validators";
  //const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
  const otp6 = helpers.regex("alpha", /(?=.*\d).{6,}/);
  /**
   * Starter page
   */
  export default {
    components: {
      Common,
    },
    props: {
      data: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        defaultName: appConfig.title,
        updateData: {
          preview_username:""
        },
        accessToken: '',
        accessEmail: '',
        accessPhone: '',
        accessUsername: '',
        account_type: '',
        loading: false,
        modalSubmit: false,
        modalLoading: false,
        passwordFieldType_current: "password",
        classicon_current: "uil uil-eye-slash",
        passwordFieldType: "password",
        classicon: "uil uil-eye-slash",
        passwordFieldType2: "password",
        classicon2: "uil uil-eye-slash",
        returnData: [],
        otpQrCode:"",
        showQR:true,
        requestChangeNew:false,
        copyTitle:"Copy",
        formData: {
          tempToken:"",
          key:"",
          password: "",
          otp: "",
        },
        formEditData: {
          id:"",
          username:"",
          name:"",
          status:"",
          login_block:"",
          permissions:[],
        }
      };
    },
    validations: {
      formData: {
        password: {
          required,
        },
        otp: {
          required,
          minLength: minLength(6),
          otp6,
        }
      },
      formEditData: {
        name: {
          required,
        },
        permissions: {
          required,
          minLength: minLength(1),
        }
      }

    },
    middleware: "authentication",
    async mounted() {
      this.reload();
    },
    created() {
      
    },
    methods: {
      copyText(value){
       // Swal.fire("Key Copied!", "You can paste this key into your authenticator app.", "success");
        const msg = value;
        navigator.clipboard.writeText(msg)
        this.$root.$emit('bv::show::tooltip', 'copyButton1')
        this.copyTitle = "Copied!";
        setTimeout(() =>{
          this.copyTitle = "Copy";
          this.$root.$emit('bv::hide::tooltip', 'copyButton1')
        },500)
        
      },
      convertDate2DayWeekandDate(value) {
          // Convert value to Date object
          const date = new Date(value);
          // Current time
          const now = new Date();
          // Time difference in milliseconds
          const diff = now - date;
          // Convert milliseconds to minutes
          const diffMinutes = Math.floor(diff / (1000 * 60));
          // Convert minutes to hours
          const diffHours = Math.floor(diff / (1000 * 60 * 60));
          // Convert hours to days
          const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
          // Convert days to weeks
          const diffWeeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));

          if (diffMinutes < 1) {
              return 'just now';
          } else if (diffMinutes < 60) {
              return `${diffMinutes} mins ago`;
          } else if (diffHours < 24) {
              return `${diffHours} hr ago`;
          } else if (diffHours < 48) {
              return '1 day ago';
          } else if (diffDays < 7) {
              return `${diffDays} days ago`;
          } else if (diffWeeks < 2) {
              return '1 week ago';
          } else {
              // Return actual date for anything older than a week
              const options = { year: 'numeric', month: 'long', day: 'numeric' };
              return date.toLocaleDateString(undefined, options);
          }
      },
      switchVisibility() {
        (this.classicon =
          this.classicon === "uil uil-eye-slash"
            ? "uil uil-eye"
            : "uil uil-eye-slash"),
          (this.passwordFieldType =
            this.passwordFieldType === "password" ? "text" : "password");
      },
      switchVisibility2() {
        (this.classicon2 =
          this.classicon2 === "uil uil-eye-slash"
            ? "uil uil-eye"
            : "uil uil-eye-slash"),
          (this.passwordFieldType2 =
            this.passwordFieldType2 === "password" ? "text" : "password");
      },
      inputNumberOnly(event, parentModel, childModel) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][childModel] = numericValue
      },
      inputNumberOnlyV2(event, parentModel, childModel) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][childModel] = numericValue
        if(numericValue.length > 2){
          var val = numericValue.replace(/[^0-9]/g, '');
          console.log(val);
          val = val.substr(0, val.length-2)+"."+val.substr(-2);
          this[parentModel][childModel] = val;
        }
      },
      inputNumberOnlyV3(event, parentModel, childModel, index) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        //this[parentModel][childModel] = numericValue
        this[parentModel].contract_selected[index][childModel] = numericValue
        if(numericValue.length > 2){
          var val = numericValue.replace(/[^0-9]/g, '');
          console.log(val);
          val = val.substr(0, val.length-2)+"."+val.substr(-2);
          this[parentModel].contract_selected[index][childModel] = val;
        }
      },
      convertCurrencyFormat(value, show00) {
        if (show00 == true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        } else {
          // Assuming this.transactionLimit is defined in your component's data
          return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
        }
      },
      initialNewQRKey(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("needChange", this.requestChangeNew)
        axios({
            method: "post",
            url:  "https://www.seveninblank.com/projects/api/anythings.php?page=initialNewQRKey",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              //this.reload()
              this.otpQrCode = "https://api.qrserver.com/v1/create-qr-code/?data=otpauth%3A%2F%2Ftotp%2FZPay+%28jey%29%3Fsecret%3DMLVJR5FXYQ35KNHZXZU22NC7X3YMO2CJ&size=150x150&ecc=M";
              this.formData.key = "MLVJR5FXYQ35KNHZXZU22NC7X3YMO2CJ"
              this.formData.tempToken = "wAHOOKvypl08AAU6gi5HrOmr"
              //remember the qr size must same as image ratio
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
      },
      showModal() {
        this.$refs['modal-2FA-setup'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
        this.requestChangeNew = false
        this.initialNewQRKey()
        //this.getReseller('create');
      },
      showChangeModal() {
        this.$refs['modal-2FA-setup'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
        this.requestChangeNew = true
        this.initialNewQRKey()
        //this.getReseller('create');
      },
      showEditModal() {
        this.$refs['modal-edit-user'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
        this.formEditData =  {
          id: this.data.updateData.account_db_code,
          username: this.data.updateData.username,
          name: this.data.updateData.name,
          status: this.data.updateData.status,
          login_block: this.data.updateData.login_block,
          permissions: this.data.updateData.modules
        }
      },
      resetModal() {
        this.loading = false;
        this.modalSubmit = false;
        this.modalLoading = false;
        this.formData= {
          tempToken:"",
          key:"",
          password: "",
          otp: "",
        },
        this.otpQrCode = "";
        this.showQR=true
        this.formEditData =  {
          id:"",
          username:"",
          name:"",
          status:"",
          login_block:"",
          permissions:[],
        }
      },
      addRequest(){
        this.modalSubmit = true
        this.$v.formData.$touch();
        console.log((this.$v.formData.$invalid))
        if (this.$v.formData.$invalid) {
          return;
        }
        else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("tempToken", this.formData.tempToken);
          bodyFormData.append("otp", this.formData.otp);
          bodyFormData.append("password", this.formData.password);
          console.log(appConfig.APIHostAdmin)
          axios({
              method: "post",
              url:  "https://www.seveninblank.com/projects/api/anythings.php?page=verifyOtp",
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                var title = ''
                var message = ''
                if (this.requestChangeNew){
                  title = 'Verification Successful'
                  message = 'Your account has been updated for 2FA and linked with your authenticator app, as the previous one is now obsolete and no longer in use.'
                }else{
                  title = 'Verification Successful'
                  message = 'Your account has been enabled for 2FA and linked with your authenticator app.'
                }
                Swal.fire({
                  icon: 'success',
                  title: title,
                  html: message,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  const updateData = {
                     "otp_enabled": true
                  }
                  this.$refs.commonFunc.updateData(JSON.stringify(updateData))
                  this.$refs['modal-2FA-setup'].hide();
                  this.$emit('callParentFunction');
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      async reload() {
        try {
          await this.$nextTick(); // Wait for the component to be fully mounted
          const commonFunc = this.$refs.commonFunc;
          if (commonFunc && commonFunc.getFullData) {
            const data = await commonFunc.getFullData();
            this.account_type = data.account_type;
            
          } else {
            // Handle the case when commonFunc or getFullData is undefined
            // Perform appropriate actions or show a fallback message to the user
          }
        } catch (error) {
          // Handle the error silently without propagating it further
        }
      },
    }
  };
</script>
<style scoped>
  label{
    margin-bottom: 5px;
  }
  #eye_current,
  #eye,
  #eye2,
  #eye3{
  position: absolute;
  top: 4px;
  padding: 5px 10px;
  right: 5px;
  text-align: center;
  width: 32px;
  color: #999;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
  }
  .custom-table td .row{
    min-width:220px;
    
  }
</style>